import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { HomePageProductCard } from './homepageproductcard';
import Slider from 'react-slick';
import { ReactComponent as NextIcon } from '../../../assets/icons/icon_right_arrow.svg';
import { ReactComponent as PrevIcon } from '../../../assets/icons/icon_left_arrow.svg';
import { segment } from '@sky-tv-group/shared';
import { useEffect } from 'react';

interface CardPanelProps {
  module: any;
  sendProduct: any;
}

const CardPanel: React.FC<CardPanelProps> = ({ module, sendProduct }) => {

  React.useEffect(() => {
    const mobile = document.querySelectorAll('.scroll-on-mobile');
    mobile.forEach((item) => {
      item.scrollLeft = 300;
    });
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true,
          nextArrow: <NextArrowMob />,
          prevArrow: <PrevArrowMob />,
          dots: true,
        },
      },
    ],
  };

  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          right: -35,
          top: 260,
        }}
        onClick={onClick}
      >
        <NextIcon />
      </div>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          left: -80,
          top: 260,
        }}
        onClick={onClick}
      >
        <PrevIcon />
      </div>
    );
  }

  function NextArrowMob(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
        }}
        onClick={onClick}
      >
        {/* <NextIcon /> */}
        <div>
          <button className={'relative'} style={{ left: '-10rem', top: slickheight+17+"px", cursor: 'pointer' }}>
            <div className={'w-12 h-12 z-100 block'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <circle cx="14" cy="14" r="14" fill="#00013A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L15.5858 14L11.2929 9.70711C10.9024 9.31658 10.9024 8.68342 11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L17.7071 13.2929C18.0976 13.6834 18.0976 14.3166 17.7071 14.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071Z" fill="white" />
              </svg>
            </div>
          </button>
        </div>
      </div>
    );
  }
  function PrevArrowMob(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
        }}
        onClick={onClick}
      >
        {/* <PrevIcon /> */}
        <div>
          <button className={'relative'} style={{ right: '-9rem', top: slickheight+17+"px", cursor: 'pointer' }}>
            <div className={'w-12 h-12 z-100 block'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <circle cx="14" cy="14" r="14" transform="matrix(-1 0 0 1 28 0)" fill="#00013A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7071 19.7071C17.0976 19.3166 17.0976 18.6834 16.7071 18.2929L12.4142 14L16.7071 9.70711C17.0976 9.31658 17.0976 8.68342 16.7071 8.29289C16.3166 7.90237 15.6834 7.90237 15.2929 8.29289L10.2929 13.2929C9.90237 13.6834 9.90237 14.3166 10.2929 14.7071L15.2929 19.7071C15.6834 20.0976 16.3166 20.0976 16.7071 19.7071Z" fill="white" />
              </svg>
            </div>
          </button>
        </div>
      </div>
    );
  }

  const [slickheight, setslickheight] = React.useState(0);
  useEffect(()=>{
    const ele  = document.getElementById("slickId");
    if(ele!=null)
    {setslickheight(ele.clientHeight / 2);}
 },[slickheight])

  return (
    <section className="section-card-panel pt-[40px] pl-[20px] pr-[0px] lg:px-[30px] mx-auto max-w-[1280px]">
      <div className="text-center font-skyBlack text-black" style={{fontWeight: 'bolder'}}>
        {documentToReactComponents(module.cardPanelHeading)}
      </div>
      <div className="product-slider slider max-w-[1200px] mx-auto">
        <Slider {...settings as any}>
          {module.cardsToShow.map((card: any, index: number) => {           
            const cardData = card.fields;
            return (cardData.type[0] === 'Bundle') ? (
              <div
                className="slide px-sky-sm flex h-full pt-14 pl-0 "
                key={index}
                style={{ marginTop: "10px" }}             
              >
                {cardData.lozengeText ? (
                  <div
                    className="font-skyBook text-white relative"
                    style={{
                      background: "linear-gradient(6deg, #254ab3, #f94cb1, #ff9e1c )",
                      fontSize: "20px",
                      lineHeight: "24px",
                      textAlign: "center",
                      padding: "8px",
                      fontWeight: 'bold',
                      borderRadius: "8px 8px 0px 0px",
                      height: '40px',
                      top: '-37px',
                      zIndex: 1,
                    }}
                  >{cardData.lozengeText}</div>
                ) : null}
                <HomePageProductCard
                  kkService={null as any}
                  handleProductCardCTA={(buttonText: string) => {
                    segment.promotionClicked(
                      `${cardData.title} - ${buttonText}`,
                      cardData.thumbnail?.fields?.file?.url ?? '',
                      "bundle offers"
                    );
                  }}
                  card={{
                    addLink: cardData.addLink,
                    bgColor: cardData.bgColor ? cardData.bgColor : '#FFFFFF',
                    billingFrequency: cardData.priceFrequencyType[0],
                    imageSrc: {
                      text: cardData.thumbnail?.fields?.file?.text ?? '',
                      url: cardData.thumbnail?.fields?.file?.url ?? '',
                    },
                    lozengeText: cardData.lozengeText,
                    sku: cardData.sku,
                    subtitle: cardData.description,
                    discountedPrice: cardData.acquistionOfferPrice ?? cardData.price,
                    title: cardData.title,
                    type: cardData.type,
                    coupon: null,
                    termsAndConditions: cardData.termsAndConditionsCaption,
                    descriptionList: cardData.detailsList,
                    copyrightText: cardData.copyrightText,
                    iconList: cardData.iconList?.map((icon: any) => ({
                      url: icon?.fields?.file?.url ?? '',
                      text: icon?.fields?.file?.text ?? '',
                    })),
                    discountText: cardData.discountText,
                    fromPrice: cardData.acquistionOfferPrice ? cardData.price : null,
                    addLinkText: cardData.addLinkText,
                    detailsText: cardData.detailsText,
                    detailsLink: cardData.detailsLink,
                    promotionImage: {
                      text: cardData.promotionImage?.fields?.file?.text ?? '',
                      url: cardData.promotionImage?.fields?.file?.url ?? '',
                    },
                  }}
                />
              </div>
            ) : null;
          })}
        </Slider>
      </div>
      <div className="text-[12px] mb-[20px] lg:mb-[64px] mt-[40px] lg:mt-[50px] w-full max-w-[85vw]">
        {documentToReactComponents(module.termsAndConditions)}
      </div>
    </section>
  );
};

export default CardPanel;
